import { isString } from "common/utils/helpers/is";
import lru from "./lru";

const defaultSettings = { provider: lru, size: 1000, ttl: 5 * 60 * 1000, timingFn: setInterval };

const toCacheKey = key => {
  if (isString(key)) return key;
  if (Array.isArray(key)) return key.map(JSON.stringify).join("_");
  return String(key);
};

export default ({ provider: CacheProvider, size, ttl, timingFn } = defaultSettings) => {
  const cache = new CacheProvider({ size, ttl });

  if (ttl) {
    timingFn(() => {
      cache.invalidate();
    }, ttl);
  }

  return {
    write: (key, value) => cache.write(toCacheKey(key), value),
    read: key => cache.read(toCacheKey(key)),
    remove: key => cache.remove(toCacheKey(key)),
    invalidate: () => cache.invalidate()
  };
};
