import axios from "axios";
import getMetaEnv from "../meta";
import { dropEmptyKeys } from "../core";
import { withCache } from "./cache";

const defaultHeaders = () => ({
  "X-CSRF-Token": getMetaEnv("csrf-token"),
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8"
});

const buildParams = ({ headers = {}, ...rest }, method) => ({
  method,
  headers: dropEmptyKeys({
    ...defaultHeaders(),
    ...headers
  }),
  credentials: "same-origin",
  ...rest
});

const haltErrorResponse = async response => {
  if (response.status >= 400 && response.status <= 599) {
    let errorMessage;
    const textBody = await response.text();
    if (textBody) {
      errorMessage = response.status === 422 ? textBody : `${response.statusText}: ${textBody}`;
    } else {
      errorMessage = response.statusText;
    }
    throw new Error(errorMessage);
  }
  return response;
};

const increaseAsyncRequestsCount = () => {
  window.ajax_active += 1;
};

const decreaseAsyncRequestsCount = () => {
  window.ajax_active -= 1;
};

const sendBeacon = (url, data) =>
  new Promise((resolve, reject) => {
    const dataString = JSON.stringify(data);
    const queued = navigator.sendBeacon(url, dataString);

    if (queued) {
      resolve();
    } else {
      reject(new Error(`Failed to queue data for sending to ${url}`));
    }
  });

const request = (url, params = {}, method = "GET") => {
  increaseAsyncRequestsCount();

  if (params.sendBeacon) {
    // eslint-disable-next-line no-param-reassign
    delete params.sendBeacon;
    return sendBeacon(url, params);
  }
  return fetch(url, buildParams(params, method))
    .then(haltErrorResponse)
    .then(response => response.json())
    .catch(error => {
      try {
        console.error(error); // eslint-disable-line no-console
      } catch (err) {
        console.error("Error has malformed data"); // eslint-disable-line no-console
      }
      throw error;
    })
    .finally(decreaseAsyncRequestsCount);
};

/**
 * A non-native implementation of request method
 * You should use default export unless you need specific functions like progress tracking
 * @param url
 * @param params
 * @param method
 * @returns {Promise<void>|Promise<unknown>}
 */
export const axiosRequest = (url, params = {}, method = "GET") => {
  increaseAsyncRequestsCount();

  if (params.sendBeacon) {
    // eslint-disable-next-line no-param-reassign
    delete params.sendBeacon;
    return sendBeacon(url, params);
  }
  return axios(url, buildParams(params, method))
    .then(haltErrorResponse)
    .then(response => response.data)
    .catch(error => {
      try {
        console.error(error); // eslint-disable-line no-console
      } catch (err) {
        console.error("Error has malformed data"); // eslint-disable-line no-console
      }
      throw error;
    })
    .finally(decreaseAsyncRequestsCount);
};

export default withCache(request);
