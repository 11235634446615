/* eslint-disable max-lines-per-function */
const rootSelector = state => state;

const createStore = (modules = {}) => {
  let state = {};
  const events = {};

  const store = {
    dispatch(event, data) {
      if (events[event]) {
        let changes = {};
        let changed;

        events[event].forEach(i => {
          const diff = i(state, data);

          if (diff) {
            changed = { ...state, ...diff };
            state = changed;
            changes = { ...changes, ...diff };
          }
        });

        if (changed) {
          const args = { changes, event, data, state };
          const submodules = event.split(".");
          if (submodules.length > 1) {
            const submodule = submodules[submodules.length - 1];
            store.dispatch(`@changed.${submodule}`, args);
          }
          store.dispatch("@changed", args);
        }
      }
    },
    get: (selector = rootSelector) => selector(state),
    on(event, cb) {
      (events[event] || (events[event] = [])).push(cb);

      return () => {
        events[event] = events[event].filter(i => i !== cb);
      };
    }
  };

  Object.keys(modules).forEach(key => {
    modules[key](store);
    store[key] = {
      change: data => store.dispatch(`@change.${key}`, data),
      changed: cb => store.on(`@changed.${key}`, cb),
      dispatch: (event, data) => store.dispatch(`${event}.${key}`, data)
    };
  });

  store.dispatch("@init");

  return store;
};

export default createStore;
