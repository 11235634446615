/* eslint-disable max-lines-per-function */
export default store => {
  store.on("@init", () => ({
    cart: {
      items: {},
      amount: 0,
      buyAgain: {
        productId: null,
        ac: null
      }
    }
  }));
  store.on("@change.cart", (state, data) => {
    const items = {
      ...state.cart.items,
      ...(data.items || {})
    };
    const amount = Object.values(items).reduce((a, b) => a + b, 0);
    return {
      cart: {
        ...state.cart,
        ...data,
        items,
        amount
      }
    };
  });
};
