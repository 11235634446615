export default store => {
  store.on("@init", () => ({
    pdp: {
      originalCartTotal: null,
      productDetailsQuantityForm: [],
      productGridForm: [],
      loadedPrices: {},
      productGridLoaded: false,
      isFetchingInventory: false,
      isSettingPDPBadges: false,
      isSettingGridInventory: false,
      fetchingSlug: null,
      savedCarts: null,
      activeCart: null,
      showActiveBadge: true,
      activeColor: null
    }
  }));
  store.on("@change.pdp", (state, data) => ({ pdp: { ...state.pdp, ...data } }));
};
