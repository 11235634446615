import { getQueryParams } from "common/utils/browser";

export default store => {
  store.on("@init", () => {
    const params = getQueryParams();
    const shopBy = ["piece", "case"].includes(params.shop_by) ? params.shop_by : undefined;
    return { ui: { shopBy } };
  });
  store.on("@change.ui", (state, data) => ({ ui: { ...state.ui, ...data } }));
};
