import { makePath, resolvePathArgs } from "./utils";
import request, { axiosRequest } from "./request";

// checks for substitutions, returns new url + request data + additional options passed
export const resolveOptions = (path, useQueryParams, ...args) => {
  const { replacements, data, options, shouldHaveReplacements } = resolvePathArgs(path, args);
  const url = makePath(path, {
    replacements: shouldHaveReplacements && replacements,
    params: useQueryParams ? data : null
  });

  return { url, data, options };
};

// request method factory, pass useAxiosForRequest if you want non-native version of request API
const buildMethod =
  (method, useQueryParams = false, options = {}) =>
  path =>
  (...args) => {
    const {
      url,
      data,
      options: { raw, useAxiosForRequest, ...restOptions }
    } = resolveOptions(path, useQueryParams, ...args);
    const body = raw || useAxiosForRequest ? data : JSON.stringify(data);
    let queryParams = { body };
    if (useAxiosForRequest) {
      queryParams = { data };
    } else if (useQueryParams) {
      queryParams = {};
    }
    const params = { ...queryParams, ...restOptions, ...options };
    if (useAxiosForRequest) {
      return axiosRequest(url, params, method);
    }
    return request(url, params, method);
  };

export const get = buildMethod("GET", true);
export const post = buildMethod("POST");
export const put = buildMethod("PUT");
export const patch = buildMethod("PATCH");
export const destroy = buildMethod("DELETE");
export const persistentPatch = buildMethod("PATCH", false, { keepalive: true });
export const nonResponsePost = buildMethod("POST", false, { sendBeacon: true });
